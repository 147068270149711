























































import { PublishActionProxy } from '../../logic/PublishActionProxy'
import AvatarsRow from '../../../../../AvatarsRow.vue'
import store from '@/store/store'

import SampleNodeMixin from 'piramis-base-components/src/components/Flow/new/logic/Nodes/SampleNodeMixin'
import ActionNode
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/ActionNode'
import { SplitTags } from 'piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  data() {
    return {
      store
    }
  },
  components: {
    AvatarsRow
  }
})
export default class PublishActionNode extends Mixins(SampleNodeMixin) {
  @Prop() action!: PublishActionProxy

  @Prop() node!: ActionNode

  getStyleName(id: number) {
    const styles = store.state.boardsState.activeBoard?.config?.styles

    if (styles) {
      const style = Object.entries(styles).find(([ _, style ]) => style.id === id)

      if (style) {
        return style[0]
      }
    }

    return '-'
  }

  get targets() {
    return store.getters.boardTargetOptions().filter(t => this.action.action.targets.includes(t.value))
  }

  get message() {
    const message = SplitTags(this.action.action.message.text).trim()

    if (message.length > 50) {
      return message.slice(0, 47) + "..."
    } else {
      return message
    }
  }
}
