








































































import { ProjectFlowAccessor } from "./types";
import DrawerWidthMixin from "@/includes/DrawerWidthMixin";
import FlowAccessorConditions from "@/components/Flow/Accessor/components/FlowAccessorConditions.vue";
import Editor from "@/components/Flow/Editor";
import EditorButtonsHelpView from "@/components/EditorButtonsHelpView.vue";
import { InputSetups } from "@/mixins/input-setups";
import { successNotification } from "@/includes/services/NotificationService";

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import ActivationActionData from "piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/components/ActivationActionData.vue";

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';
import { instanceToInstance } from "class-transformer";
import { cloneDeep } from "lodash";

type ConditionData = Pick<ProjectFlowAccessor, 'conditions' | 'action'> | null

@Component({
  data() {
    return {
      EditorButtonsHelpView
    }
  },
  components: {
    FlowAccessorConditions,
    ConfigField,
    ActivationActionData
  }
})
export default class FlowAccessor extends Mixins(DrawerWidthMixin, InputSetups) {
  @VModel() accessor!: ProjectFlowAccessor | null

  @Prop() editor!: Editor

  accessorValue: ConditionData = null

  @Watch('accessor', { deep: true, immediate: true })
  onAccessorChange(value: ConditionData) {
    if (value) {
      this.accessorValue = {
        action: cloneDeep(value.action),
        conditions: instanceToInstance(value.conditions)
      }
    } else {
      this.accessorValue = null
    }
  }

  saveAccessorModel() {
    if (this.accessor && this.accessorValue) {
      this.accessor.conditions = instanceToInstance(this.accessorValue.conditions)
      this.accessor.action = cloneDeep(this.accessorValue.action)

      successNotification()
    }
  }

  resetAccessor() {
    this.accessorValue = this.accessor = null

    successNotification()
  }

  created() {
    this.initializeSidebarWidth(992)

    if (this.accessor) {
      const targets = this.accessor.getChannelMemberConditionTargets()

      if (targets.length) {
        this.editor.getChannelsInfo(targets)
      }
    }
  }
}
