import CashRegistersCore from 'piramis-base-components/src/components/Shop/CashRegisters'
import {
	CashRegistersState,
	CreateCashRegisterModel,
	CashRegisterModel,
	UpdateCashRegisterModel,
} from 'piramis-base-components/src/components/Shop/CashRegisters/types'
import store from '@/store/store'
import { ShopService } from '@/includes/services/ShopService'

export default class CashRegisterHelper extends CashRegistersCore {
	constructor(cashRegisters: Array<CashRegisterModel>) {
		super(cashRegisters)
	}

	protected createCashRegister(system: CreateCashRegisterModel): Promise<CashRegisterModel> {
		return store.dispatch('createCashRegister', system)
	}

	protected updateCashRegisters(system: UpdateCashRegisterModel): Promise<CashRegisterModel> {
		return store.dispatch('editCashRegister', system)
	}

	protected deleteCashRegisters(id: number): Promise<CashRegistersState> {
		return store.dispatch('deleteCashRegister', id)
	}

	protected restoreCashRegister(id: number): Promise<void> {
		return ShopService.restoreCashRegister('tg', { id, board_key: store.state.boardsState.activeBoard!.board })
	}
}
