import i18n from 'vue-i18n'
import DelayNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayNode'
import { DelayNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/types'
import store from '@/store/store'

export class DelayNode extends DelayNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: DelayNodeProps

    if (readonly) {
      props = {
        getProperties: () => []
      }
    } else {
      props = {
        getProperties: () => store.getters.properties
      }
    }

    super(i18n, props)
  }

}