import store from "@/store/store";
import router from "@/router";
import RunFlowButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RunFlowButton'
import CreateInviteLinkSampleNode
  from '@/components/Flow/Actions/CreateInviteLink/ui/Node/CreateInviteLinkNode.vue'
import CreateInviteLinkSidebar
  from '@/components/Flow/Actions/CreateInviteLink/ui/Sidebar/CreateInviteLinkSidebar.vue'
import { CreateInviteLinkAction } from "../../Actions/CreateInviteLink/logic/CreateInviteLinkAction";
import { CreateInviteLinkActionProxy } from "../../Actions/CreateInviteLink/logic/CreateInviteLinkActionProxy";
import { JoinRequestAction } from '@/components/Flow/Actions/JoinRequest/logic/JoinRequestAction'
import { JoinRequestActionProxy } from '@/components/Flow/Actions/JoinRequest/logic/JoinRequestActionProxy'
import JoinRequestSidebar from '@/components/Flow/Actions/JoinRequest/ui/Sidebar/JoinRequestSidebar.vue'
import JoinRequestNode from '@/components/Flow/Actions/JoinRequest/ui/Node/JoinRequestNode.vue'
import customMediaTypes from '@/components/MessageEditorCustomAttachments/Properties/customMediaTypes'
import PopupButtonButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/PopupButton'
import SendMessageNodeEditorMessage from "@/components/HelpMessages/editor/SendMessageNodeEditorMessage.vue";
import RedirectButtonBuilder from '@/components/ProjectButtons/NewEditorButtons/RedirectButton'
import { DeleteLastMessageAction } from "../../Actions/DeleteLastMessageAction/logic/DeleteLastMessageAction";
import {
  DeleteLastMessageActionProxy
} from '@/components/Flow/Actions/DeleteLastMessageAction/logic/DeleteLastMessageActionProxy'
import DeleteLastMessageActionSidebar
  from '@/components/Flow/Actions/DeleteLastMessageAction/ui/Sidebar/DeleteLastMessageActionSidebar.vue'
import DeleteLastMessageActionNode
  from '@/components/Flow/Actions/DeleteLastMessageAction/ui/Node/DeleteLastMessageActionNode.vue'

import { BanAction } from '@/components/Flow/Actions/Ban/logic/BanAction'
import { BanActionProxy } from '@/components/Flow/Actions/Ban/logic/BanActionProxy'
import BanActionSidebar from '@/components/Flow/Actions/Ban/ui/Sidebar/BanActionSidebar.vue'
import BanActionNode from '@/components/Flow/Actions/Ban/ui/Node/BanActionNode.vue'
import { PublishAction } from '@/components/Flow/Actions/Publish/logic/PublishAction'
import { PublishActionProxy } from '@/components/Flow/Actions/Publish/logic/PublishActionProxy'
import PublishActionSidebar from '@/components/Flow/Actions/Publish/ui/Sidebar/PublishActionSidebar.vue'
import PublishActionNode from '@/components/Flow/Actions/Publish/ui/Node/PublishActionNode.vue'

import SendMessageNodeBuilder from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/SendMessage/logic/SendMessageNode'
import { SendMessageNodeProps } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/SendMessage/logic/types'
import RunFlowNodeButtonBuilder from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/RunFlowNodeButton";
import LinkButtonBuilder from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link";
import ShareButtonBuilder from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share";
import FlowFormButtonBuilder from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FlowButtons/FlowFormButton";
import { PlaceholdersType } from "piramis-base-components/src/components/Editor/components/Toolbar/types";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { ActionTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'

import i18n from 'vue-i18n'

export class SendMessageNode extends SendMessageNodeBuilder {
  constructor(i18n: i18n, readonly: boolean) {
    let props: SendMessageNodeProps

    if (readonly) {
      props = {
        availableButtonsTypes: [],
        placeholders: () => ({
          type: PlaceholdersType.Ones,
          placeholders: []
        }),
        buttonHelpView: SendMessageNodeEditorMessage,
        createNewLabel: () => {
          throw new Error('Attempt to create a label with a screenshot')
        },
        createNewProperty: () => {
          throw new Error('Attempt to create a property with a screenshot')
        },
        getLabels: () => ({}),
        getProperties: () => [],
        target: '',
        targetOptions: () => [],
        targets: () => [],
        usersOptions: () => [],
        externalActions: [ {
          type: ActionTypes.CreateInviteLink,
          action: CreateInviteLinkAction,
          proxy: CreateInviteLinkActionProxy,
          sidebar: CreateInviteLinkSidebar,
          node: CreateInviteLinkSampleNode,
        }, {
          type: ActionTypes.JoinRequest,
          action: JoinRequestAction,
          proxy: JoinRequestActionProxy,
          sidebar: JoinRequestSidebar,
          node: JoinRequestNode,
        },
          {
            type: ActionTypes.DeleteLastMessageAction,
            action: DeleteLastMessageAction,
            proxy: DeleteLastMessageActionProxy,
            sidebar: DeleteLastMessageActionSidebar,
            node: DeleteLastMessageActionNode,
          },
          {
            type: ActionTypes.Ban,
            action: BanAction,
            proxy: BanActionProxy,
            sidebar: BanActionSidebar,
            node: BanActionNode,
          },
          {
            type: ActionTypes.Publish,
            action: PublishAction,
            proxy: PublishActionProxy,
            sidebar: PublishActionSidebar,
            node: PublishActionNode,
          }
        ],
      }
    } else {
      props = {
        availableButtonsTypes: [
          new RunFlowNodeButtonBuilder(i18n),
          new RunFlowButtonBuilder(i18n, () => store.getters.flowOptions),
          new LinkButtonBuilder(i18n),
          new ShareButtonBuilder(i18n),
          new FlowFormButtonBuilder(i18n, () => store.getters.formsSelectOptions, () => {
            router.push({
              name: 'Forms',
              params: {
                id: router.currentRoute.params[ EntityTypes.BOT_ID ]
              }
            })
          }),
          new PopupButtonButtonBuilder(i18n),
          new RedirectButtonBuilder(i18n)
        ],
        customMediaTypes: customMediaTypes,
        placeholders: () => store.getters.flowPlaceholders,
        buttonHelpView: SendMessageNodeEditorMessage,

        createNewLabel: (label) => store.dispatch('createLabel', label),
        createNewProperty: (path: string, name: string, parent?: number) => store.dispatch('addBoardProperty', { path, name, parent } ),
        getLabels: () => store.getters.labels,
        getProperties: () => store.getters.propertiesList.filter(p => p.path !== 'InviteLink'),

        target: store.state.boardsState.activeBoard!.board,

        targets: () => store.getters.botTargets,
        targetOptions: () => store.getters.boardTargetOptions(),
        usersOptions: () => store.getters.boardAdmins,

        externalActions: [ {
          type: ActionTypes.CreateInviteLink,
          action: CreateInviteLinkAction,
          proxy: CreateInviteLinkActionProxy,
          sidebar: CreateInviteLinkSidebar,
          node: CreateInviteLinkSampleNode,
        },
          {
          type: ActionTypes.JoinRequest,
          action: JoinRequestAction,
          proxy: JoinRequestActionProxy,
          sidebar: JoinRequestSidebar,
          node: JoinRequestNode,
        },
          {
            type: ActionTypes.DeleteLastMessageAction,
            action: DeleteLastMessageAction,
            proxy: DeleteLastMessageActionProxy,
            sidebar: DeleteLastMessageActionSidebar,
            node: DeleteLastMessageActionNode,
          },
          {
            type: ActionTypes.Ban,
            action: BanAction,
            proxy: BanActionProxy,
            sidebar: BanActionSidebar,
            node: BanActionNode,
          },
          {
            type: ActionTypes.Publish,
            action: PublishAction,
            proxy: PublishActionProxy,
            sidebar: PublishActionSidebar,
            node: PublishActionNode,
          } ],
      }
    }

    super(i18n, props)
  }
}